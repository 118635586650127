
<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="400"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        dark
        medium
        right
        fixed
        bottom
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-tabs v-model="tabs" background-color="primary" dark>
          <v-tab> Insert customer </v-tab>
          <v-tab> Import customer </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card flat>
              <v-card-text class="pa-7">
                <v-text-field
                  prepend-icon="mdi-account-box-outline"
                  label="Name"
                  v-model="name"
                  :error-messages="nameErrors"
                  required
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-cellphone"
                  label="Contact"
                  v-model="contact"
                  :error-messages="contactErrors"
                  placeholder="Example: 0112345678"
                  required
                  @input="$v.contact.$touch()"
                  @blur="$v.contact.$touch()"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-email"
                  label="Email"
                  v-model="email"
                  :error-messages="emailErrors"
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                ></v-text-field>

                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Birthday(Optional)"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
           
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @click="menu2 = false"
                    dark
                    color="secondary"
                  ></v-date-picker>
                </v-menu>
                <v-textarea
                  clearable
                  prepend-icon="mdi-map-marker"
                  clear-icon="mdi-close-circle"
                  label="Address(Optional)"
                  v-model="address"
                ></v-textarea>

                <v-alert dense :type="status" v-if="alertBox">{{
                  text
                }}</v-alert>
              </v-card-text>
              <v-card-actions class="mx-auto">
                <v-btn
                  color="secondary"
                  class="white--text"
                  @click="checkCustomerContactAndSubmit"
                  width="100"
                  >Add</v-btn
                >
                <v-btn color="secondary" outlined @click="dialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="pa-7">
                <v-text-field
                  label="Choose excel file"
                  @click="file=null,pickFile(),alertBox2=false"
                  v-model="fileName"
                  readonly
                  prepend-icon="mdi-file-excel"
                  hint="support csv/xls/xlsx file."
                  persistent-hint
                  required
                ></v-text-field>

                <span style="color: #FF8F00"
                  >*Please remove the header or title before upload file* <br>
                    (name, contact, email, birthday(yyyy-mm-dd), address)<br>
                   if no information please leave blank!
                </span>

                <input
                  type="file"
                  style="display: none"
                  ref="image"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  @change="onChange"
                />

                <v-alert dense :type="status2" v-if="alertBox2">{{
                  text2
                }}</v-alert>
              </v-card-text>
              <v-card-actions class="mx-auto">
                <v-btn
                  color="secondary"
                  class="white--text"
                  @click="importCustomer"
                  width="100"
                  >Import</v-btn
                >
                <v-btn color="secondary" outlined @click="dialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
   
      
    </template></v-dialog
  >
  
</template>

<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

import XLSX from "xlsx";
export default {
  mixins: [validationMixin],

  validations: {
    name: { required },
    contact: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    email: { required, email },
    // address: { required },
    // date: { required },
  },

  data: () => ({
    domain: BASEURL,
    dialog: false,
    company_id: "",
    tabs: null,
    menu2: false,
    file: "",
    fileData: "",
    fileName: "",
    date: "",
    name: "",
    contact: "",
    address: "",
    email: "",
    customerExist: "",
    status: "",
    alertBox: false,
    text: "",
    status2: "",
    alertBox2: false,
    text2: "",

  }),
  created() {
    var data = JSON.parse(this.getCookie("login"));
    this.company_id = data.company_id;
  },

  watch: {},
  computed: {
    // computedDateFormattedMomentjs() {
    //   var moment = require("moment"); // require
    //   return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    // },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Customer name is required");
      return errors;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.numeric && errors.push("Phone must consist only number");
      !this.$v.contact.minLength &&
        errors.push("Phone must at least 10 number");
      !this.$v.contact.maxLength &&
        errors.push("Phone maxinmum consist of 11 number");
      !this.$v.contact.required && errors.push("Phone is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    // addressErrors() {
    //   const errors = [];
    //   if (!this.$v.address.$dirty) return errors;
    //   !this.$v.address.required && errors.push("Address is required");
    //   return errors;
    // },
    // dateErrors() {
    //   const errors = [];
    //   if (!this.$v.date.$dirty) return errors;
    //   !this.$v.date.required && errors.push("Birthday is required");
    //   return errors;
    // },
  },
  methods: {
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        this.fileName = event.target.files[0].name;
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.fileData = data;
        };

        reader.readAsBinaryString(this.file);
      }
    },
    pickFile() {
      this.$refs.image.click();
    },

    getAllCustomer() {
      const params = new URLSearchParams();
      params.append("getAllCustomer", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.$emit("update", response.data.customer);
          } else {
            console.log("no customer found on this company");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkCustomerContactAndSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const params = new URLSearchParams();
        params.append("getCustomerInfo", "done");
        params.append("contact", this.contact);
        params.append("company_id", this.company_id);
        axios({
          method: "post",
          url: this.domain + "/customer/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.alertBox = true;
              this.status = "error";
              this.text = "Phone number exist";
            } else {
              this.insertCustomer();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    insertCustomer() {
      const params = new URLSearchParams();
      params.append("create", "done");
      params.append("name", this.name);
      params.append("contact", this.contact);
      params.append("email", this.email);
      params.append("birthday", this.date);
      params.append("address", this.address);
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.$v.$reset();
            this.alertBox = false;
            this.dialog = false;
            this.name = "";
            this.contact = "";
            this.email = "";
            this.date = "";
            this.address = "";
            this.getAllCustomer();
          } else {
            this.alertBox = true;
            this.status = "error";
            this.text = "soemthing went wrong";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    importCustomer() {
      if (this.file == "" && this.fileData == "" && this.fileName == "") {
        this.alertBox2 = true;
        this.status2 = "error";
        this.text2 = "File is required";
      } else {
        const params = new URLSearchParams();
        params.append("importCustomer", "done");
        params.append("data", JSON.stringify(this.fileData));
        params.append("company_id", this.company_id);
        axios({
          method: "post",
          url: this.domain + "/customer/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.file = null;
              this.fileName = "";
              this.fileData = null;
              this.getAllCustomer();
            } else {
              this.alertBox2= true;
              this.status2 = "error";
              this.text2 = "soemthing went wrong";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
    

    
