<template>
  <div class="text-center" style="margin: 163px 0px;">
    <v-btn
      class="mx-2"
      fab
      x-large
      disabled
      depressed
      style="font-size: 399px !important"
    >
      <v-icon x-large> mdi-format-list-checkbox </v-icon>
    </v-btn>
    <br />
    <div class="text-h5 my-5">{{ name }}</div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>